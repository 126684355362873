<template>
  <div class="languages-data-table-component">
    <v-data-table
        :headers="headers"
        :items="languages"
        sort-by="name"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Sprachen</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                Neue Sprache
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-alert v-show="error"
                         color="red"
                         type="error"
                >{{error}}</v-alert>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.isocode"
                        label="ISO Code"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                          label="Bild"
                          accept=".gif,.jpg,.png"
                          show-size
                          v-model="file"
                          v-bind:loading="fileUploading"
                      >
                        <template slot="progress">
                          <v-progress-circular v-model="uploadProgress">
                            {{uploadProgress}}%
                          </v-progress-circular>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="540px">
            <v-card>
              <v-card-title class="headline">Wollen Sie diesen Eintrag wirklich löschen?</v-card-title>
              <v-card-text>
                <v-alert v-show="error"
                         color="red"
                         type="error"
                >{{error}}</v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          bearbeiten
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          löschen
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.flag_img_url="{ item }">
        <img :src="item.flag_img_url" class="flag-img">
      </template>
      <template v-slot:item.standard="{ item }">
        <v-checkbox v-model="item.standard" v-on:change="setStandard($event, item)"></v-checkbox>
      </template>
      <template v-slot:item.active="{ item }">
        <v-checkbox v-model="item.active" v-on:change="setActive($event, item)"></v-checkbox>
      </template>

      <template v-slot:no-data>
        <v-alert
            v-show="error"
            color="red"
            type="error">
          {{error}}
        </v-alert>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Neuladen
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
        color="red"
        v-model="showErrorSnackbar"
    >{{error}}</v-snackbar>
  </div>
</template>

<style lang="css">
  .flag-img {
    height: 20px;
  }
</style>

<script>
import persistentStorage from "@/services/PersistentStorage";
export default {
  name: "LanguagesDataTable",
  data: () => ({
    error: '',
    dialog: false,
    dialogDelete: false,
    uploadProgress: 0,
    fileUploading: false,
    file: null,
    headers: [
      {text: 'Sprache', value: 'name'},
      {text: 'ISO Code', value: 'isocode'},
      {text: 'Flagge', value: 'flag_img_url'},
      {text: 'Standard', value: 'standard'},
      {text: 'Aktiv', value: 'active'},
      { text: 'Aktionen', value: 'actions', sortable: false }
    ],
    languages: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      isocode: '',
      flag_img_url: '',
      standard: false,
      active: false
    },
    defaultItem: {
      name: '',
      isocode: '',
      flag_img_url: '',
      standard: false,
      active: false
    },
  }),

  computed: {
    showErrorSnackbar: {
      set(val) {
        if(val == false) {
          this.error = '';
        }
      },
      get() {
        return this.error != ''
      }
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Neue Sprache' : 'Sprache bearbeiten'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.queryLanguages('');
    },

    editItem (item) {
      this.editedIndex = this.languages.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.error = '';
      this.file = null;
    },

    deleteItem (item) {
      this.editedIndex = this.languages.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.error = '';
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      persistentStorage.deleteObject('languages', this.languages[this.editedIndex].id)
          .then(
              function() {
                this.queryLanguages();
                this.closeDelete();
              }.bind(this),
              function(error) {
                this.error = error;
              }.bind(this));
    },

    close () {
      this.dialog = false
      this.error = '';
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      persistentStorage.saveObject('languages', this.editedItem)
          .then(function(response) {
            this.editedItem.id = response.id;
            this.error = '';
            if(this.file === null) {
              this.close();
              this.queryLanguages();
            } else {
              persistentStorage.uploadFile('languages/' + this.editedItem.id + '/flag_img', null, this.file,
                  function(progress) {
                    this.uploadProgress = Math.round(progress.loaded * 100 / progress.total);
                  }.bind(this))
                  .then(function() {
                    this.close();
                    this.queryLanguages();
                  }.bind(this), function(error) {
                    this.error = error;
                  }.bind(this));
            }
          }.bind(this), function(error) {
            this.error = error;
          });
    },
    async queryLanguages (filter) {
      this.loading = true;
      persistentStorage.getObjectList('languages', filter)
          .then(function(result) {
            this.languages = result;
            this.loading = false;
          }.bind(this), function(error) {
            this.languages = [];
            this.error = error;
            this.loading = false;
          }.bind(this));
    },
    setStandard(evt, languageItem) {
      for(let language of this.languages) {
        language.standard = false;
      }
      languageItem.standard = true;
      persistentStorage.updateObject('languages', languageItem).then(function() {
        this.queryLanguages('');
      }.bind(this));

    },
    setActive(evt, languageItem) {
      languageItem.active = evt;
      persistentStorage.updateObject('languages', languageItem).then(function() {
        this.queryLanguages('');
      }.bind(this));
    }
  },
}
</script>