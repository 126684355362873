<template>
  <LanguagesDataTable></LanguagesDataTable>
</template>

<script>
import LanguagesDataTable from "@/components/LanguagesDataTable";
export default {
name: "Languages",
  components: {LanguagesDataTable}

}
</script>

<style scoped>

</style>